<template>
<Default>
  <template v-slot:breadcrumb>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>Anasayfa</a-breadcrumb-item>
      <a-breadcrumb-item>Ayarlar</a-breadcrumb-item>
    </a-breadcrumb>
  </template>
  <template v-slot:content>
    <div class="content">
      <div class="content-head">
        <h3>Ayarlar</h3>
      </div>

      <a-tabs tab-position="left" v-model:activeKey="activeKey">

        <a-tab-pane key="1" tab="Kişisel Bilgiler">
          <GeneralInformations :userInfo="userInfo" />
        </a-tab-pane>

        <a-tab-pane key="2" tab="Bildirim Ayarlarım">
          <NotificationSettings :userInfo="userInfo" />
        </a-tab-pane>

      </a-tabs>

    </div>
  </template>
  <template v-slot:mTitle>Ayarlarım | Bill</template>
</Default>
</template>

<script>
import { defineComponent, ref } from 'vue';
import Default from "@/components/_layouts/Default";

/* Components */
import GeneralInformations from "@/views/Settings/components/GeneralInformations";
import NotificationSettings from "@/views/Settings/components/NotificationSettings";

export default defineComponent({
  data(){
    return {
      userInfo: null
    }
  },
  components: {
    Default, GeneralInformations, NotificationSettings
  },
  setup() {
    return {
      activeKey: ref('1'),
    };
  },
  created() {
    this.userInfo = this.$store.getters.authUserInfo
  }
});
</script>

<style scoped>
.content {
  padding: 24px;
  background: #fff;
  min-height: 360px;
}

.content-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
</style>