<template>
  <div class="content">

    <a-form layout="vertical" :model="formState">
      <a-form-item label="Gerçek Zamanlı Bildirimleri Al">
        <a-switch v-model:checked="formState.liveNotify"/>
      </a-form-item>
      <a-form-item label="Bildirim Sesleri (Açık/Kapalı)">
        <a-switch v-model:checked="formState.notifySound"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="submit">Değişiklikleri Kaydet</a-button>
      </a-form-item>
    </a-form>

    <!--    <a-button @click="console">Userinfo Console</a-button>-->
  </div>
</template>
<script>
import {defineComponent, reactive, computed} from 'vue';
import {EditOutlined} from "@ant-design/icons-vue"
import instance from "@/http/axios";
import {message} from 'ant-design-vue';

export default defineComponent({
  setup() {
    const formState = reactive({
      liveNotify: true,
      notifySound: true
    });
    return {
      formState
    };
  },
  components: {
    EditOutlined
  },
  props: ['userInfo'],
  methods: {
    submit() {

      let formData = new FormData();
      formData.append('liveNotify', this.formState.liveNotify)
      formData.append('notifySound', this.formState.notifySound)

      this.$store.state.authUser.settings.notification.liveNotify = this.formState.liveNotify
      this.$store.state.authUser.settings.notification.notifySound = this.formState.notifySound

      instance.post('/user/update/self/notifySettings', formData).then(response => {
        if (response.data.code === 200) message.success(response.data.msg)
        else message.error(response.data.msg)
      })

    }
  },
  mounted() {
    this.formState.liveNotify = this.userInfo.settings.notification.liveNotify ?? false
    this.formState.notifySound = this.userInfo.settings.notification.notifySound ?? false
  }
});
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: row;
}
</style>