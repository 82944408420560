<template>
  <div class="content">

    <a-form layout="vertical" :model="formState">
      <a-form-item label="Ad & Soyad">
        <a-input v-model:value="formState.name" placeholder="adınız ve soyadınız"/>
      </a-form-item>
      <a-form-item label="E-Posta">
        <a-input v-model:value="formState.email" placeholder="e-posta adresiniz"/>
      </a-form-item>
      <a-alert style="margin-bottom: 10px" message="Şifrenizi değiştirmek istiyorsanız yazın" type="warning" show-icon/>
      <a-form-item label="Şuanki Şifre">
        <a-input v-model:value="formState.oldPassword" placeholder="şuanki şifreniz"/>
      </a-form-item>
      <a-form-item label="Şifre">
        <a-input v-model:value="formState.password" :disabled="!(formState.oldPassword.length > 0)" placeholder="yeni şifreniz"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="submit">Değişiklikleri Kaydet</a-button>
      </a-form-item>
    </a-form>

    <!--    <a-button @click="console">Userinfo Console</a-button>-->
  </div>
</template>
<script>
import {defineComponent, reactive, computed} from 'vue';
import {EditOutlined} from "@ant-design/icons-vue"
import instance from "@/http/axios";
import crypto from "crypto";
import {message} from 'ant-design-vue';

export default defineComponent({
  setup() {
    const formState = reactive({
      name: '',
      email: '',
      password: '',
      oldPassword: ''
    });
    return {
      formState
    };
  },
  components: {
    EditOutlined
  },
  props: ['userInfo'],
  methods: {
    submit() {

      let formData = new FormData();
      formData.append('name', this.formState.name)
      formData.append('email', this.formState.email)
      if (this.formState.password.length > 0 && this.formState.oldPassword.length > 0) {
        formData.append('oldPassword', crypto.createHash('md5').update(this.formState.oldPassword).digest('hex'))
        formData.append('password', crypto.createHash('md5').update(this.formState.password).digest('hex'))
      }

      instance.post('/user/update/self/generalInfo', formData).then(response => {
        if (response.data.code === 200) message.success('Bilgileriniz başarıyla güncellendi!')
        else message.error(response.data.msg)

        this.formState.password = ""
        this.formState.oldPassword = ""
      })

    }
  },
  mounted() {
    this.formState.name = this.userInfo.name
    this.formState.email = this.userInfo.email
  }
});
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: row;
}
</style>